import React from "react";
import { graphql } from "gatsby";
import { PrivacyCard } from "../components/PageComps/CommonComps";
import StandardSEO from "../components/common/seo/StandardSEO";

const CookiesPolicyPage = ({ data }) => {
  const { sanityCookiesPolicyPage } = data;

  return <PrivacyCard data={sanityCookiesPolicyPage} />;
};

export default CookiesPolicyPage;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityCookiesPolicyPage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityCookiesPolicyPage(language: { eq: $language }) {
      seo {
        title
        description
        keywords
        name
        type
      }
      pageHead {
        title
        subTitle
      }
      point: privacyPolicy {
        sectionTitle
        sectionPoints
      }
      updatedAt: _updatedAt
    }
  }
`;
